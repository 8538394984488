import logo from './logo.svg';
import './App.css';
import Index from './views/main';

function App() {
  return (
    <div className="App">

      <Index />

    </div>
  );
}

export default App;
